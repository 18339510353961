<script setup lang="ts">
import {
    getCategoryRoute,
    getTranslatedProperty,
} from '@shopware-pwa/helpers-next';

const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const { navigationElements } = useNavigation();
const sideMenuController = useSideMenuModal();
const { count } = useCart();

const isMobileSearchActive = ref(false);
const isSideMenuOpen = ref(sideMenuController.isOpen);

const handleCloseSideMenu = () => {
    sideMenuController.close();
    isMobileSearchActive.value = false;
};

defineEmits(['openCartMenu']);
</script>

<template>
    <button
        class="lg:hidden bg-transparent z-205 pl-2"
        aria-label="Show side menu"
        @click="sideMenuController.open"
        v-if="!isSideMenuOpen"
    >
        <div class="i-custom-svg:mobile-menu w-8 h-8" />
    </button>
    <button
        v-else
        class="lg:hidden bg-transparent z-205"
        aria-label="Show side menu"
        @click="sideMenuController.close"
    >
        <div class="i-carbon-close w-8 h-8" />
    </button>
    <ClientOnly>
        <LayoutSidebar :controller="sideMenuController" side="left">
            <!-- Header -->
            <div
                class="sidebar-header flex items-center justify-between py-5 px-5"
            >
                <button
                    class="bg-transparent z-205"
                    aria-label="Show side menu"
                    @click="handleCloseSideMenu"
                >
                    <div class="i-carbon-close w-6 h-6" />
                </button>
                <NuxtLink
                    :to="formatLink(`/`)"
                    @click="sideMenuController.close"
                >
                    <span class="sr-only">SN super.natural Online Shop</span>
                    <img v-lazy-load 
                        class="h-10 w-10 lg:h-12 lg:w-12"
                        data-src="/logo/sn_logo_schwarz.svg"
                        alt="SN Logo"
                        width="40"
                        height="40"
                        
                    />
                </NuxtLink>
                <button
                    class="group bg-transparent flex items-center relative"
                    aria-label="Mini cart"
                    @click="
                        () => {
                            handleCloseSideMenu();
                            $emit('openCartMenu');
                        }
                    "
                >
                    <div
                        :class="
                            count > 0
                                ? 'i-custom-svg:cart-filled'
                                : 'i-custom-svg:cart'
                        "
                        class="w-6 h-6 text-dark hover:text-brand-primary"
                    />
                    <span
                        v-if="count > 0"
                        class="text-3 pt-2 font-sm text-white absolute w-6 h-6 text-center flex items-center justify-center"
                    >
                        {{ count > 100 ? '99+' : count || '' }}
                    </span>
                    <span class="sr-only"
                        >{{ $t('cart.itemsInCart') }},
                        {{ $t('cart.viewCart') }}</span
                    >
                </button>
            </div>

            <aside
                aria-label="Sidebar"
                class="mobile-sidebar-menu px-3 pb-15 mt-5 h-full overflow-y-scroll"
            >
                <div v-if="isMobileSearchActive" class="search-wrapper-mobile">
                    <p class="text-left uppercase text-2xl pl-4">
                        {{ $t('sidebar.search') }}
                    </p>
                    <LayoutStoreSearch
                        @link-clicked="sideMenuController.close"
                        :isMobileSearchActive="isMobileSearchActive"
                        @closeSearch="isMobileSearchActive = false"
                        is-mobile="true"
                    />
                </div>
                <div
                    class="min-h-[650px] h-full flex flex-col justify-between"
                    v-else
                >
                    <ZAvant :dynamic-height="false">
                        <!-- LEVEL 1 -->
                        <ZAvantItem
                            v-for="navigationElement in navigationElements"
                            :key="navigationElement.id"
                            class="blocktext-left !mt-0 border-b-1 border-color-border-grey"
                        >
                            <ZAvantMenu
                                v-if="navigationElement?.children?.length > 0"
                            >
                                <div
                                    class="submenu-title font-bold text-left py-2"
                                >
                                    {{ navigationElement.name }}
                                </div>

                                <template #next>
                                    <span
                                        class="text-[18px] font-medium uppercase"
                                        >{{ navigationElement.name }}</span
                                    >
                                </template>
                                <template #back>
                                    <span>Menü</span>
                                </template>
                                <!-- LEVEL 2 -->
                                <ZAvantItem
                                    v-for="(
                                        childElement, index
                                    ) in navigationElement.children"
                                    :key="childElement.id"
                                    class="block text-left !mt-0 border-b-1 border-color-border-grey"
                                >
                                    <ZAvantMenu
                                        v-if="
                                            childElement?.children?.length > 0
                                        "
                                    >
                                        <div
                                            class="submenu-title font-bold text-left py-2"
                                        >
                                            {{ childElement.name }}
                                        </div>

                                        <template #next>
                                            <span
                                                class="text-[18px] font-medium uppercase"
                                                >{{ childElement.name }}</span
                                            >
                                        </template>
                                        <template #back>
                                            <span>{{
                                                navigationElement.name
                                            }}</span>
                                        </template>
                                        <!-- LEVEL 3 -->
                                        <ZAvantItem
                                            v-for="(
                                                grandChildElement, index
                                            ) in childElement.children"
                                            :key="childElement.id"
                                            class="block text-left !mt-0 border-b-1 border-color-border-grey"
                                        >
                                            <NuxtLink
                                                :to="
                                                    formatLink(
                                                        getCategoryRoute(
                                                            grandChildElement
                                                        )
                                                    )
                                                "
                                                :target="
                                                    grandChildElement.externalLink ||
                                                    grandChildElement.linkNewTab
                                                        ? '_blank'
                                                        : ''
                                                "
                                            >
                                                <div
                                                    class="block"
                                                    :class="{
                                                        'max-w-200px md:max-w-300px':
                                                            !!grandChildElement.media,
                                                    }"
                                                    @click="
                                                        sideMenuController.close
                                                    "
                                                >
                                                    <p
                                                        class="text-[18px] font-medium text-gray-900"
                                                    >
                                                        {{
                                                            getTranslatedProperty(
                                                                grandChildElement,
                                                                'name'
                                                            )
                                                        }}
                                                    </p>
                                                    <p
                                                        v-if="
                                                            getTranslatedProperty(
                                                                grandChildElement,
                                                                'description'
                                                            )
                                                        "
                                                        class="mt-1 text-sm text-gray-500"
                                                        v-html="
                                                            getTranslatedProperty(
                                                                grandChildElement,
                                                                'description'
                                                            )
                                                        "
                                                    />
                                                </div>
                                            </NuxtLink>
                                        </ZAvantItem>
                                    </ZAvantMenu>
                                    <ZAvantItem v-else class="py-0">
                                        <NuxtLink
                                            role="menuitem"
                                            :target="
                                                childElement.externalLink ||
                                                childElement.linkNewTab
                                                    ? '_blank'
                                                    : ''
                                            "
                                            :to="
                                                formatLink(
                                                    getCategoryRoute(
                                                        childElement
                                                    )
                                                )
                                            "
                                            class="flex text-[18px] font-medium text-gray-900 uppercase"
                                            @click="sideMenuController.close"
                                            >{{ childElement.name }}</NuxtLink
                                        >
                                    </ZAvantItem>
                                </ZAvantItem>
                            </ZAvantMenu>
                            <ZAvantItem v-else class="py-0">
                                <NuxtLink
                                    role="menuitem"
                                    :target="
                                        navigationElement.externalLink ||
                                        navigationElement.linkNewTab
                                            ? '_blank'
                                            : ''
                                    "
                                    :to="
                                        formatLink(
                                            getCategoryRoute(navigationElement)
                                        )
                                    "
                                    class="flex text-[18px] font-medium text-gray-900 uppercase"
                                    @click="sideMenuController.close"
                                    >{{ navigationElement.name }}</NuxtLink
                                >
                            </ZAvantItem>
                        </ZAvantItem>
                    </ZAvant>

                    <div class="flex flex-col gap-5 items-start mt-15">
                        <!-- Account -->
                        <AccountMenu :label="$t('sidebar.account')" />
                        <!-- Wishlist -->
                        <NuxtLink
                            class="flex items-center relative gap-3"
                            aria-label="wishlist"
                            data-testid="wishlist-button"
                            @click="sideMenuController.close"
                            :to="formatLink(`/wishlist`)"
                        >
                            <div
                                :class="
                                    wishlistCount > 0
                                        ? 'i-custom-svg:heart-filled'
                                        : 'i-custom-svg:heart'
                                "
                                class="w-6 h-6 text-dark hover:text-brand-primary"
                            />
                            <span
                                v-if="wishlistCount > 0"
                                class="text-3 pt-1 font-sm text-white absolute w-6 h-6 text-center flex items-center justify-center"
                            >
                                {{
                                    wishlistCount > 100
                                        ? '99+'
                                        : wishlistCount || ''
                                }}
                            </span>
                            <span>{{ $t('sidebar.wishlist') }}</span>
                        </NuxtLink>
                        <!-- Search -->
                        <button
                            class="group bg-transparent flex items-center relative gap-3"
                            @click="isMobileSearchActive = true"
                        >
                            <div
                                class="i-carbon-search w-6 h-6 text-dark hover:text-brand-primary"
                            />
                            <span class="sr-only"
                                >{{ $t('cart.itemsInCart') }},
                                {{ $t('cart.viewCart') }}</span
                            >
                            <span>{{ $t('sidebar.search') }}</span>
                        </button>
                    </div>
                </div>
            </aside>
        </LayoutSidebar>
    </ClientOnly>
</template>
<style lang="scss">
$chevron-size: 10px;
@mixin chevronStyle {
    border-style: solid;
    border-width: 2px 2px 0 0;
    border-radius: 1px;
    width: $chevron-size;
    height: $chevron-size;
    border-color: var(--color-dark);
    content: '';
    display: inline-block;
}
.zavant__next:after {
    @include chevronStyle;
    position: absolute;
    transform: rotate(45deg);
    right: $chevron-size;
}

.zavant__back:before {
    @include chevronStyle;
    position: relative;
    transform: rotate(-135deg);
    left: calc($chevron-size / 2);
}

.zavant__item--back {
    border-bottom: 1px solid rgb(212, 212, 212);
}
</style>
